import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HttpService } from '../../../../../shareds/services/http.service';
import { Router, ActivatedRoute, Event } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { AppService } from '../../../../../app.service';
import { appUrl } from '../../../../../app.url';
import { environment } from '../../../../../../environments/environment';

@Component({
  selector: 'app-add-button',
  templateUrl: './add-button.component.html',
  styleUrls: ['./add-button.component.scss']
})
export class AddButtonComponent implements OnInit {
  bootname: string;
  bootid: number;
  active: string;
  productId: number;
  status: string;
  constructor(private http: HttpService,
    private build: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private dom: DomSanitizer,
    private app: AppService) { }

  ngOnInit() {
    this.formControl();
    this.bootname = this.activatedRoute.snapshot.params['name'];
    this.bootid = parseInt(this.activatedRoute.snapshot.params['id']);
    this.active = this.activatedRoute.snapshot.params['action'];
    this.status = this.activatedRoute.snapshot.params['status'];
    this.productId = this.activatedRoute.snapshot.params['actionid'];
    if (this.bootid) this.getButton()
    this.type = "A"
    this.form.get('ba_name').setValue(this.bootname);
  }

  getButton() {
    this.http.methodGet(`api/BOBoothAccessories/${this.productId}`).subscribe(res => {
      let items = res['data'];
      // items.ba_img_1 = `${environment.images}${items.ba_img_1}`;
      // items.ba_img_2 = `${environment.images}${items.ba_img_2}`;
      // items.ba_img_3 = `${environment.images}${items.ba_img_3}`;
      // items.ba_img_4 = `${environment.images}${items.ba_img_4}`;
      this.form.patchValue(items)
      this.form.get('ba_name').setValue(this.bootname);
      this.type = res['data']['ba_type_key']

      if (items.ba_doc_file) this.URLPdf = { name: items.ba_doc_file, url: `${environment.images}/${items.ba_doc_file}` }
      // if (items.Video) this.URLVideo = { name: items.ba_vdo, url: `${environment.video}${items.ba_vdo}` }
      if (items.ba_img_vdo) this.URLimagesVideo = { name: items.ba_img_vdo, url: `${environment.images}/${items.ba_img_vdo}` }
      if (items.ba_img_1) this.URLImages1 = { id: 1, name: items.ba_img_1, url: `${environment.images}/${items.ba_img_1}` };
      if (items.ba_img_2) this.URLImages2 = { id: 2, name: items.ba_img_2, url: `${environment.images}/${items.ba_img_2}` };
      if (items.ba_img_3) this.URLImages2 = { id: 3, name: items.ba_img_3, url: `${environment.images}/${items.ba_img_3}` };
      if (items.ba_img_4) this.URLImages3 = { id: 4, name: items.ba_img_4, url: `${environment.images}/${items.ba_img_4}` };
    })
  }

  form: FormGroup;
  formControl() {
    this.form = this.build.group({
      ba_position_x: [""],
      ba_position_y: [""],
      ba_isactive: [true],
      booth_id: [this.bootid],
      ba_type_key: ["A"],
      ba_name: [""],
      ba_detail: [""],
      ba_doc_file_is_edit: [false],
      ba_doc_file: [""],
      ba_vdo: [""],
      ba_img_vdo: [""],
      ba_img_vdo_is_edit: [false],
      ba_img: [""],
      ba_img_icon: [""],
      ba_img_1: [""],
      ba_img_2: [""],
      ba_img_3: [""],
      ba_img_4: [""],
      ba_img_1_is_edit: [false],
      ba_img_2_is_edit: [false],
      ba_img_3_is_edit: [false],
      ba_img_4_is_edit: [false],
    })
  }

  type: string
  selectTypeButton(type: any) {
    this.type = type.value;
    let x = this.form.value['ba_position_x']
    let y = this.form.value['ba_position_y']
    if (type.value == 'C') this.URLimagesVideo = ""
    let name = this.form.value['ba_name']
    this.form.reset();
    this.form.get('ba_name').setValue(name)
    this.form.get('ba_position_y').setValue(y)
    this.form.get('ba_position_x').setValue(x)
    this.form.get('ba_type_key').setValue(this.type)


  }

  cancel() {
    this.router.navigate(['/', appUrl.boothmanage, this.bootname, 'booth', this.bootid, this.active])
  }

  selectFile(items: any) {
    items.click();
  }

  fileimages: any = [];
  arrayURLImages: any = [];
  URLImages1: any;
  URLImages2: any;
  URLImages3: any;
  URLImages4: any;
  URLimagesVideo: any;
  URLVideo: any;
  URLPdf: any;
  progressBar: number;
  selectImages(event: any, type?: string, keyupload?: string, id?: any) {
    this.progressBar = 0;
    const feedback = document.getElementById('feedback');
    const progresss: any = document.getElementById('progress');
    const file = event.target.files && event.target.files[0];
    if (file) {
      file.status = 'create';
      var reader = new FileReader();
      reader.readAsDataURL(file)
      if (keyupload == 'file') {
        if (file.name.split(".")[1] == "xlsx" || file.name.split(".")[1] == "pdf" || file.name.split(".")[1] == "ppt") {
          this.URLPdf = ""
          this.URLPdf = { name: file.name, url: this.dom.bypassSecurityTrustUrl(URL.createObjectURL(file)) }
          this.form.get('ba_doc_file').setValue([file])
          this.form.get('ba_doc_file_is_edit').setValue(true)
          return;
        }
        else {
          event.target.value = "";
          alert('ต้องเป็นไฟล์ PDF, DOC, PPT เท่านั้น');
          return;
        }
      }
      if (file.type.indexOf(`${type}`) >= 0) {
        if (keyupload != 'video') reader.onload = (event) => {
          // if (keyupload === 'img') {
          //   this.URLimagesVideo = { name: file.name, url: (<FileReader>event.target).result }
          //   this.form.get('ba_img_vdo').setValue([file])
          //   this.form.get('ba_img_vdo_is_edit').setValue(true)
          // }
          if (keyupload === 'img1' || keyupload === 'img2' || keyupload === 'img3' || keyupload === 'img4') {
            this[`URLImages${id}`] = { id: id, name: file.name, url: (<FileReader>event.target).result }
            this[`URLImages${id}`] = { id: id, name: file.name, url: (<FileReader>event.target).result }
            this.form.get(`ba_img_${id}`).setValue([file])
            this.form.get(`ba_img_${id}_is_edit`).setValue(true)
          }
        }
        if (keyupload == 'video') reader.onprogress = (event) => {
          if (event.loaded > 1153209714) {
            let msg = `<span style="color:red;">ไฟล์ขนาดเกินกำหนด</span>`;
            feedback.innerHTML = msg;
            return;
          } else {
            if (event.loaded && event.total) {
              if (keyupload == 'video') {
                const percent = (event.loaded / event.total) * 100;
                progresss.value = percent;
                this.progressBar = progresss.value;
                document.getElementById('progress-label').innerHTML = Math.round(percent) + '%';
                if (percent === 100) {
                  let msg = `<span style="color:green;">File <u><b>${file.name}</b></u>.</span>`;
                  feedback.innerHTML = msg;
                  this.URLVideo = "";
                  setTimeout(() => {
                    // this.URLVideo = { name: file.name, url: (<FileReader>event.target).result }
                    this.form.get('VideoFile').setValue([file])
                  }, 500);
                }
              }
            }
          }
        }


      } else {
        event.target.value = "";
        if (keyupload == 'video') return alert("ต้องเป็นวิดีโอเท่านั้น");
        if (keyupload == 'pdf') return alert("ต้องเป็น PDF นั้น");
        else alert("ต้องเป็นรูปภาพเท่านั้น");

      }

    }
  }

  create() {
    this.form.get('booth_id').setValue(this.bootid)
    this.form.get('ba_isactive').setValue(true);
    if (!this.form.valid) return alert("กรอกข้อมูลที่มี * ให้ครบ");
    this.http.uploadfilePOST2(`api/BOBoothAccessories`, this.form.value).subscribe(res => {
      alert('เพิ่มปุ่มแล้ว');
      this.router.navigate(['/', appUrl.boothmanage, this.bootname, 'booth', this.bootid, this.active])
    })
  }

  upload() {
    this.http.uploadfilePUT2(`api/BOBoothAccessories/${this.productId}`, this.form.value).subscribe(res => {
      alert('แก้ไขข้อมูลแล้ว');
      this.router.navigate(['/', appUrl.boothmanage, this.bootname, 'booth', this.bootid, this.active])
    })

  }


  deleteImg(items: any, input: any) {
    this.form.get(`ba_img_${items.id}`).setValue('')
    this.form.get(`ba_img_${items.id}_is_edit`).setValue(true)
    input.value = ""
    this[`URLImages${items.id}`] = ""
  }

  loadSize(items, img, input) {
    if (img.naturalWidth >= 1280 && img.naturalHeight >= 800) {
      // this.arrayURLImages.splice(this.arrayURLImages.indexOf(del => del == items), 1);
      // this.fileimages.splice(this.fileimages.indexOf(del => del.name == items.name), 1);
      alert('ขนาดรูปไม่ตรงที่ต้องการ');
      input.value = ""
      return;
    }
  }
}

