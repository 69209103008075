import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from 'src/app/app.service';
import { appUrl } from 'src/app/app.url';
import { HttpService } from 'src/app/shareds/services/http.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-add-images',
  templateUrl: './add-images.component.html',
  styleUrls: ['./add-images.component.scss']
})
export class AddImagesComponent implements OnInit {

  constructor(
    private http: HttpService,
    private build: FormBuilder,
    private router: Router,
    private app: AppService,
    private activatedRoute: ActivatedRoute,
    private dom: DomSanitizer,
  ) { }

  panoramaId: number;
  ngOnInit() {
    this.formControl();
    this.panoramaId = this.activatedRoute.snapshot.params['id'];
    if (this.panoramaId) this.getItemsPositionByID();

  }

  getItemsPositionByID() {
    this.http.methodGet(`api/BOPanorama/panorama/${this.panoramaId}`).subscribe((res: any) => {
      let items = res.data;
      this.form.patchValue(items);
      if (items.panorama_img) this.URLImages1 = { id: 1, name: items.panorama_img, url: `${environment.images}/${items.panorama_img}` };
    })
  }

  form: FormGroup;
  formControl() {
    this.form = this.build.group({
      panorama_name: "",
      panorama_img: "",
      panorama_img_isedit: false,
      panorama_isactive: true
    })
  }

  createPanorama() {
    let model: any = this.form.value;
    delete model.panorama_img_isedit;
    this.http.uploadfilePOST2(`api/BOPanorama/panorama`, model).subscribe(res => {
      // alert("เพิ่มตำแหน่งเดินเรียบร้อย");
      this.cancel();
    })
  }

  updatePanorama() {
    this.http.uploadfilePUT2(`api/BOPanorama/panorama/${this.panoramaId}`, this.form.value).subscribe(res => {
      // alert("แก้ไขตำแหน่งเดินเรียบร้อย");
      // this.cancel();
    })
  }

  cancel() {
    this.router.navigate(['/', appUrl.panorama])
  }

  selectFile(items: any) {
    items.click();
  }

  fileimages: any = [];
  arrayURLImages: any = [];
  URLImages1: any;
  URLImages2: any;
  URLImages3: any;
  URLImages4: any;
  URLimagesVideo: any;
  URLVideo: any;
  URLPdf: any;
  progressBar: number;
  selectImages(event: any, type?: string, keyupload?: string, id?: any) {
    this.progressBar = 0;
    const feedback = document.getElementById('feedback');
    const progresss: any = document.getElementById('progress');
    const file = event.target.files && event.target.files[0];
    if (file) {
      file.status = 'create';
      var reader = new FileReader();
      reader.readAsDataURL(file)
      if (keyupload == 'file') {
        if (file.name.split(".")[1] == "xlsx" || file.name.split(".")[1] == "pdf" || file.name.split(".")[1] == "ppt") {
          this.URLPdf = ""
          this.URLPdf = { name: file.name, url: this.dom.bypassSecurityTrustUrl(URL.createObjectURL(file)) }
          this.form.get('ba_doc_file').setValue([file])
          this.form.get('ba_doc_file_is_edit').setValue(true)
          return;
        }
        else {
          event.target.value = "";
          alert('ต้องเป็นไฟล์ PDF, DOC, PPT เท่านั้น');
          return;
        }
      }
      if (file.type.indexOf(`${type}`) >= 0) {
        if (keyupload != 'video') reader.onload = (event) => {
          // if (keyupload === 'img') {
          //   this.URLimagesVideo = { name: file.name, url: (<FileReader>event.target).result }
          //   this.form.get('ba_img_vdo').setValue([file])
          //   this.form.get('ba_img_vdo_is_edit').setValue(true)
          // }
          if (keyupload === 'img1' || keyupload === 'img2' || keyupload === 'img3' || keyupload === 'img4') {
            this[`URLImages${id}`] = { id: id, name: file.name, url: (<FileReader>event.target).result }
            this[`URLImages${id}`] = { id: id, name: file.name, url: (<FileReader>event.target).result }
            this.form.get(`panorama_img`).setValue([file])
            this.form.get(`panorama_img_isedit`).setValue(true)
          }
        }
        if (keyupload == 'video') reader.onprogress = (event) => {
          if (event.loaded > 1153209714) {
            let msg = `<span style="color:red;">ไฟล์ขนาดเกินกำหนด</span>`;
            feedback.innerHTML = msg;
            return;
          } else {
            if (event.loaded && event.total) {
              if (keyupload == 'video') {
                const percent = (event.loaded / event.total) * 100;
                progresss.value = percent;
                this.progressBar = progresss.value;
                document.getElementById('progress-label').innerHTML = Math.round(percent) + '%';
                if (percent === 100) {
                  let msg = `<span style="color:green;">File <u><b>${file.name}</b></u>.</span>`;
                  feedback.innerHTML = msg;
                  this.URLVideo = "";
                  setTimeout(() => {
                    // this.URLVideo = { name: file.name, url: (<FileReader>event.target).result }
                    this.form.get('VideoFile').setValue([file])
                  }, 500);
                }
              }
            }
          }
        }


      } else {
        event.target.value = "";
        if (keyupload == 'video') return alert("ต้องเป็นวิดีโอเท่านั้น");
        if (keyupload == 'pdf') return alert("ต้องเป็น PDF นั้น");
        else alert("ต้องเป็นรูปภาพเท่านั้น");

      }

    }
  }

  deleteImg(items: any, input: any) {
    this.form.get(`panorama_img`).setValue('')
    this.form.get(`panorama_img_isedit`).setValue(true)
    input.value = ""
    this[`URLImages${items.id}`] = ""
  }

  loadSize(items, img, input) {
    if (img.naturalWidth >= 1280 && img.naturalHeight >= 800) {
      // this.arrayURLImages.splice(this.arrayURLImages.indexOf(del => del == items), 1);
      // this.fileimages.splice(this.fileimages.indexOf(del => del.name == items.name), 1);
      alert('ขนาดรูปไม่ตรงที่ต้องการ');
      input.value = ""
      return;
    }
  }

}
