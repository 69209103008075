import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from 'src/app/app.service';
import { appUrl } from 'src/app/app.url';
import { HttpService } from 'src/app/shareds/services/http.service';

@Component({
  selector: 'app-detail-booth',
  templateUrl: './detail-booth.component.html',
  styleUrls: ['./detail-booth.component.scss']
})
export class DetailBoothComponent implements OnInit {

  tabs = [
    { active: 'users', name: 'ผู้ใช้งาน', status: false },
    { active: 'product', name: 'สินค้า', status: false },
    { active: 'faq', name: 'คำถามบ่อย', status: false },
    { active: 'chat', name: 'แชท', status: false },
    // { active: 'button', name: 'ปุ่มแสดงข้อมูล', status: true },
    { active: 'button', name: 'ผลงาน', status: true },
  ]

  active: string;
  constructor(private http: HttpService,
    private build: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private app: AppService) { }

  bootname: string;
  bootid: number;
  ngOnInit() {
    if (this.app.RoleCode == 'AD' || this.app.RoleCode == 'SU') {
      this.tabs.find(fn => fn.active == 'product').status = true;
    }
    // console.log(this.app.RoleCode);

    this.bootname = this.activatedRoute.snapshot.params['name'];
    this.bootid = this.activatedRoute.snapshot.params['id'];
    this.active = this.activatedRoute.snapshot.params['action'];
  }

  tabActive(tab: string) {
    this.active = tab;
    this.router.navigate(['/', appUrl.boothmanage, this.bootname, 'booth', this.bootid, tab])
  }

  cancel() {
    this.router.navigate(['/', appUrl.boothmanage])
  }


}
