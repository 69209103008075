import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from 'src/app/app.service';
import { appUrl } from 'src/app/app.url';
import { HttpService } from 'src/app/shareds/services/http.service';

@Component({
  selector: 'app-view-hotpots',
  templateUrl: './view-hotpots.component.html',
  styleUrls: ['./view-hotpots.component.scss']
})
export class ViewHotpotsComponent implements OnInit {

  constructor(
    private http: HttpService,
    private router: Router,
    private app: AppService,
    private activatedRoute: ActivatedRoute,
    private title: Title
  ) { }
  positionId: number;
  nameHotspots: string
  ngOnInit() {
    this.positionId = this.activatedRoute.snapshot.params['positionid'];
    this.nameHotspots = this.activatedRoute.snapshot.params['name'];
    this.geItemsHotspots();

  }

  itemsPoistion: any = []
  geItemsHotspots() {
    this.http.methodGet(`api/BOPosition/hotspot/byposition/${this.positionId}`).subscribe((res: any) => {
      this.itemsPoistion = res.data;
    })
  }

  addHotspots() {
    this.router.navigate(['/', appUrl.position, this.nameHotspots, 'hs', this.positionId, 'add'])
  }

  updateHotspots(items) {
    this.router.navigate(['/', appUrl.position, this.nameHotspots, 'hs', this.positionId, 'update', items.hotspot_Id])
  }

  delelteHotSpots(items) {
    let txt;
    let alertTxt = confirm("ต้องการลบ ?");
    if (alertTxt == true) {
      this.http.methodDelete(`api/BOPosition/hotspot/${items.hotspot_Id}`).subscribe(res => {
        this.geItemsHotspots()
      }, err => {
        alert(`${err.error.message}`)
      })
    } else {
      txt = "You pressed Cancel!";
    }

  }

  toPosition() {
    this.router.navigate(['/', appUrl.position]);
  }
}
