import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AppService } from 'src/app/app.service';
import { appUrl } from 'src/app/app.url';
import { HttpService } from 'src/app/shareds/services/http.service';

@Component({
  selector: 'app-position',
  templateUrl: './position.component.html',
  styleUrls: ['./position.component.scss']
})
export class PositionComponent implements OnInit {

  constructor(
    private http: HttpService,
    private router: Router,
    private app: AppService,
    private title: Title
  ) { }

  ngOnInit() {
    this.geeItemsPosition();

  }

  itemsPoistion: any = []
  itemsDefault: any = [];
  geeItemsPosition() {
    this.http.methodGet(`api/BOPosition`).subscribe((res: any) => {
      this.itemsDefault = res.data;
      this.itemsPoistion = this.itemsDefault;
    })
  }

  filter(v: any) {
    this.itemsPoistion = []
    let filterItems = this.itemsDefault.filter((fl: any) => fl.position_name == v.value)
    if (filterItems) this.itemsPoistion = filterItems;



  }

  addPosition() {
    this.router.navigate(['/', appUrl.position, 'add'])
  }

  updatePosition(items) {
    this.router.navigate(['/', appUrl.position, 'update', items.position_Id])
  }

  viewHotSpots(items) {
    this.router.navigate(['/', appUrl.position, items.position_name, 'hs', items.position_Id])
  }
}
