import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AppService } from 'src/app/app.service';
import { appUrl } from 'src/app/app.url';
import { HttpService } from 'src/app/shareds/services/http.service';

@Component({
  selector: 'app-pano-images',
  templateUrl: './pano-images.component.html',
  styleUrls: ['./pano-images.component.scss']
})
export class PanoImagesComponent implements OnInit {

  constructor(
    private http: HttpService,
    private router: Router,
    private app: AppService,
    private title: Title
  ) { }

  ngOnInit() {
    this.getItemsPosition();

  }

  itemsPanorama: any = []
  getItemsPosition() {
    this.http.methodGet(`api/BOPanorama/panorama`).subscribe((res: any) => {
      this.itemsPanorama = res.data;
    })
  }

  addPanorama() {
    this.router.navigate(['/', appUrl.panorama, 'add'])
  }

  updatePanorama(items) {
    this.router.navigate(['/', appUrl.panorama, 'update', items.panorama_Id])
  }

  deletePanorama(items) {
    let txt;
    let alertTxt = confirm("ต้องการลบ ?");
    if (alertTxt == true) {
      this.http.methodDelete(`api/BOPanorama/panorama/${items.panorama_Id}`).subscribe(res => {
        this.getItemsPosition();
      }, err => {
        alert(`${err.error.message}`)
      })
    } else {
      txt = "You pressed Cancel!";
    }
  }


}
