import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from 'src/app/app.service';
import { appUrl } from 'src/app/app.url';
import { HttpService } from 'src/app/shareds/services/http.service';

@Component({
  selector: 'app-add-hotspots',
  templateUrl: './add-hotspots.component.html',
  styleUrls: ['./add-hotspots.component.scss']
})
export class AddHotspotsComponent implements OnInit {

  constructor(
    private http: HttpService,
    private build: FormBuilder,
    private router: Router,
    private app: AppService,
    private activatedRoute: ActivatedRoute,
    private dom: DomSanitizer,
  ) { }

  positionId: number;
  hotpspotsId: number;
  nameHotspots: number;
  ngOnInit() {
    this.positionId = this.activatedRoute.snapshot.params['positionid'];
    this.hotpspotsId = this.activatedRoute.snapshot.params['id'];
    this.nameHotspots = this.activatedRoute.snapshot.params['name'];
    this.geItemsPosition();
    this.getBooth();
    this.formControl();
    this.form.get('position_id').setValue(this.positionId)
    if (this.hotpspotsId) this.geItemsHotspotsById();

  }

  itemsPoistion: any = []
  geItemsPosition() {
    this.http.methodGet(`api/BOPosition`).subscribe((res: any) => {
      this.itemsPoistion = res.data;
    })
  }

  itemBooth: any = [];
  getBooth() {
    this.http.methodGet(`api/MSBoothAccessories/Find`).subscribe(res => {
      this.itemBooth = res['data'];
    })
  }


  geItemsHotspotsById() {
    this.http.methodGet(`api/BOPosition/hotspot/${this.hotpspotsId}`).subscribe((res: any) => {
      this.form.patchValue(res.data);
      if (res.data['create_tooltip_func'] == 'W') this.form.get('create_tooltip_args').setValue("iconWalk");
    })
  }

  form: FormGroup;
  formControl() {
    this.form = this.build.group({
      position_id: 0,
      sceneid: "",
      pitch: 0,
      yaw: 0,
      css_class: "",
      hotspot_type: "",
      create_tooltip_func: "",
      create_tooltip_args: "",
      click_handler_func: "",
      hotspot_isactive: true,
      targetYaw: "0",
      targetPitch: "0"
    })
  }

  createHotpots() {
    this.http.methodPost(`api/BOPosition/hotspot`, this.form.value).subscribe(res => {
      // alert("เพิ่ม Hotspots เรียบร้อย");
      this.cancel();
    })
  }

  updateHotpots() {
    this.http.methodPut(`api/BOPosition/hotspot/${this.hotpspotsId}`, this.form.value).subscribe(res => {
      // alert("แก้ไข Hotspots เรียบร้อย");
      this.cancel();
    })
  }

  cancel() {
    this.router.navigate(['/', appUrl.position, this.nameHotspots, 'hs', this.positionId])
  }

  selectTypeButton(v: any) {
    if (v.value == "W") {
      this.form.get('create_tooltip_func').setValue('W');
      this.form.get('create_tooltip_args').setValue('iconWalk');
      this.form.get('css_class').setValue('');
      this.form.get('hotspot_type').setValue('scene');

    }
    if (v.value == "V") {
      this.form.get('create_tooltip_func').setValue('V');
      this.form.get('create_tooltip_args').setValue('');
      this.form.get('css_class').setValue('hotspot-title img1');
      this.form.get('hotspot_type').setValue('');
    }

    if (v.value == "D") {
      this.form.get('create_tooltip_func').setValue('D');
      this.form.get('create_tooltip_args').setValue('Door');
      this.form.get('css_class').setValue('');
      this.form.get('hotspot_type').setValue('scene');
    }

    if (v.value == "F") {
      this.form.get('create_tooltip_func').setValue('F');
      this.form.get('create_tooltip_args').setValue('');
      this.form.get('css_class').setValue('hotspot-title img1');
      this.form.get('hotspot_type').setValue('');
    }
  }

  selectProduct(v: any) {
    let product = this.itemBooth.find(fn => fn.ba_id == v.value)
    this.form.get('sceneid').setValue(product.ba_name);
    this.form.get('create_tooltip_args').setValue(product.ba_name);
    this.form.get('click_handler_func').setValue(v.value);
  }

  selectPosition(v: any) {
    if (this.form.value['create_tooltip_func'] == "D") {
      let position = this.itemsPoistion.find(fn => fn.position_Id == v.value);
      if (position) this.form.get('create_tooltip_args').setValue(position.position_name);
    }

  }
}
