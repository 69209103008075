import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from 'src/app/app.service';
import { appUrl } from 'src/app/app.url';
import { HttpService } from 'src/app/shareds/services/http.service';

@Component({
  selector: 'app-add-position',
  templateUrl: './add-position.component.html',
  styleUrls: ['./add-position.component.scss']
})
export class AddPositionComponent implements OnInit {

  constructor(
    private http: HttpService,
    private build: FormBuilder,
    private router: Router,
    private app: AppService,
    private activatedRoute: ActivatedRoute,
    private dom: DomSanitizer,
  ) { }

  positionId: number;
  ngOnInit() {
    this.formControl();
    this.positionId = this.activatedRoute.snapshot.params['id'];
    if (this.positionId) this.getItemsPositionByID();
    this.getItemsPosition();

  }

  getItemsPositionByID() {
    this.http.methodGet(`api/BOPosition/${this.positionId}`).subscribe((res: any) => {
      console.log(res.data);

      this.form.patchValue(res.data);
    })
  }

  itemsPanorama: any = []
  getItemsPosition() {
    this.http.methodGet(`api/BOPanorama/panorama`).subscribe((res: any) => {
      this.itemsPanorama = res.data;
    })
  }

  form: FormGroup;
  formControl() {
    this.form = this.build.group({
      position_name: "",
      panorama_select_id: "0",
      hfov: 0,
      yaw: 0,
      position_type: "equirectangular",
      position_isactive: true
    })
  }

  createPosition() {
    this.http.methodPost(`api/BOPosition`, this.form.value).subscribe(res => {
      // alert("เพิ่มตำแหน่งเดินเรียบร้อย");
      this.cancel();
    })
  }

  updatePosition() {
    this.http.methodPut(`api/BOPosition/${this.positionId}`, this.form.value).subscribe(res => {
      // alert("แก้ไขตำแหน่งเดินเรียบร้อย");
      this.cancel();
    })
  }

  cancel() {
    this.router.navigate(['/', appUrl.position])
  }

  selectPano(v: any) {
    let pano = this.itemsPanorama.find(fn => fn.panorama_Id == v.value)
    this.form.get('position_name').setValue(pano.panorama_name)
    this.form.get('panorama_select_id').setValue(v.value)
  }

}
